import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

export function get_eb24_pusher() {
  if (undefined === window.eb24_pusher) {
    let options = {
      broadcaster: 'pusher',
      key: import.meta.env.VITE_PUSHER_APP_KEY,
      encrypted: true,
      withoutInterceptors: true,
      cluster: '',
      wsHost: import.meta.env.VITE_PUSHER_HOST,
      wsPort: import.meta.env.VITE_PUSHER_PORT,
      wssPort: import.meta.env.VITE_PUSHER_PORT,
      forceTLS: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss']
    };

    window.eb24_pusher = new Echo(options);
  }

  return window.eb24_pusher;
}
